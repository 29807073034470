@import 'theme.scss';

.barAssociationEvaluations {
  h2 {
    letter-spacing: 2px;
    margin: 0 0 $space-sm;
    text-transform: uppercase;
  }
}

.highlyQualified svg {
  color: #5f9f9e;
}

.qualified svg {
  color: #e0b46c;
}

.notQualified svg {
  color: #dc7e6d;
}

.notEvaluated svg {
  color: #858180;
}
.totals {
  margin-top: $margin-cozy;
  margin-bottom: $margin-comfortable;
}

.total {
  display: flex;
  flex-direction: row-reverse;
  font-weight: $font-weight-bold;
  color: $color-gray-dark;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 2px;
  align-items: center;
  justify-content: flex-end;
  margin: $margin-cozy-vertical-only;
}

.total .hexagon {
  margin-right: $margin-compact;
}

.highlyQualifiedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #5f9f9e;
  }
  :global(.trapezoid__right) {
    border-left-color: #407f7e;
  }
}

.qualifiedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #e0b46c;
  }
  :global(.trapezoid__right) {
    border-left-color: #d8a552;
  }
}

.notQualifiedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #dc7e6d;
  }
  :global(.trapezoid__right) {
    border-left-color: #cf5d48;
  }
}

.notEvaluatedHexagon {
  :global(.trapezoid__left) {
    border-right-color: #858180;
  }
  :global(.trapezoid__right) {
    border-left-color: #474747;
  }
}

@media (min-width: $breakpoint-md) {
  .totals {
    display: flex;
    justify-content: space-around;
    margin-top: 0rem;
    margin-bottom: $margin-cozy;
  }
  .total {
    flex-direction: column;
    align-items: center;
    font-size: $text-xs;
    margin-right: $margin-cozy;
  }
  .total .hexagon {
    margin: $margin-compact-vertical-only;
  }
}

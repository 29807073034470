@import 'theme.scss';

.LocalNav__container {
  background: $color-white;

  .LocalNav__GlobalAlert {
    color: $color-black;
    margin: 0;
    padding: 0 1rem;
  }

  .LocalNav__ShareableBallotBanner {
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;

    .Notification__body .Button {
      margin-top: 0.5rem;
    }
  }
}

.LocalNav {
  align-items: center;
  background: $color-white;
  border-bottom: 1px solid #dedede;
  display: flex;
  height: $nav-height;
  justify-content: space-between;
}

.LocalNav__center {
  justify-content: center;
}

.LocalNav__sticky {
  position: sticky;
  top: 0;
}

.LocalNav__BrandContainer {
  display: flex;
  height: 100%;
}

.LocalNav__Brand {
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 1rem;
  width: 10rem;
}

.LocalNav__Brand img {
  margin: 0;
  max-width: 10rem;
  min-width: 10rem;
  object-fit: contain;
  object-position: 0 center;
}

.LocalNav__Inner {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  margin-right: 1rem;

  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.LocalNav .Button {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
}

.LocalNav__Back {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  left: 0;
  margin-left: 1rem;
  padding: 0;
  position: absolute;
}

.LocalNav__Left {
  display: flex;
  height: 100%;
}

.addressBtn {
  background: transparent;
  border: none;
  color: getColor(var(--color-primary), $color-primary);
  cursor: pointer;
  font-size: $text-xl;
  margin-right: $space-xxs;
  width: 29px;

  &:hover {
    color: var(--link-color);
  }

  @media (min-width: $breakpoint-lg) {
    margin-right: 0;
  }
}

.localeSwitcher {
  display: none;
  margin-left: $space-sm;

  @media (min-width: $breakpoint-lg) {
    display: block;
  }
}

@media (min-width: $breakpoint-sm) {
  .LocalNav__center .LocalNav__Inner {
    position: absolute;
    right: 0;
  }

  .LocalNav {
    height: $nav-height-lg;
  }

  .LocalNav__container {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 101;
  }

  .LocalNav__container .Notification {
    height: $global-alert-height;
  }
}

@media print {
  .LocalNav {
    display: none;
  }
}

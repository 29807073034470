@import 'theme.scss';

$primary: getColor(var(--color-primary), $color-primary);

.container {
  border: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 20rem;
  margin-bottom: 3rem;
  padding: unset;
  text-align: left;

  @media (min-width: $breakpoint-md) {
    margin-top: 2rem;
  }
}

.radioItem {
  display: flex;
}

.radioLabel {
  font-size: $text-sm;
  margin-bottom: $space-xxs;
  @media (min-width: $breakpoint-sm) {
    font-size: $text-sm;
  }
}

.radioInput {
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + .radioControl::before {
    transform: scale(1);
  }

  &:focus + .radioControl {
    box-shadow:
      0 0 0 0.05em #fff,
      0 0 0.15em 0.1em $color-black;
  }
}

.radioControl {
  border-radius: 50%;
  border: 0.1rem solid $color-gray-dark;
  display: grid;
  height: 1rem;
  margin-right: 0.5rem;
  min-width: 1rem;
  place-items: center;
  transform: translateY(0.25rem);
  width: 1rem;

  &::before {
    box-shadow: inset 0.5em 0.5em $primary;
    border-radius: 50%;
    content: '';
    height: 0.5rem;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
    width: 0.5rem;
  }
}

.success {
  display: flex;
  svg {
    margin: 0.25rem;
  }
  p {
    margin: 0;
  }
}

.yes {
  color: var(--brand-success);
}

.no {
  color: var(--brand-danger);
}

:root {
  /* Colors */
  --color-black: #212529;
  --color-gray-dark: #757575;
  --color-gray: #D8D8D8;
  --color-gray-light: #ececec;
  --color-gray-extra-light: #f6f6f6;
  --color-white: #fff;

  --color-primary-red: 42;
  --color-primary-green: 82;
  --color-primary-blue: 104;

  --color-primary: rgb(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue)
  );
  --color-primary-darkened: #001F35;
  --color-primary-lightened: #5D859B;
  --color-secondary: #a6b7c0;
  --color-light: #DDEDFF;

  --brand-danger: #E22529;
  --brand-disabled: var(--color-gray-light);
  --brand-success: #008547;
  --brand-warning: #FFA400;

  /* Typography */
  --font-family-base: 'Lato', sans-serif;
  --font-family-handwriting: 'Indie Flower', cursive;
  --font-family-headings: 'Lato', sans-serif;
  --font-family-headings--fancy: 'Montserrat', sans-serif;

  /* Nav */
  --nav-height: 3.5rem;
  --nav-height--lg: 5rem;

  /* Spinner */
  --spinner-color: var(--color-primary);
  --spinner-color-ring: rgba(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue),
    .3
  );

  /* Links */
  --link-color: #0156AC;
  --link-color--hover: #175DAF;

  /* Global Alert */
  --global-alert-height: 3rem;
  --share-banner-height: 5rem;

  /* Lead Bar */
  --lead-bar-height: 8rem;

  /* CSC: Header */
  --header-background: var(--color-primary);
  --header-color: var(--color-light);

  /* CSC: CardList */
  --card-list-width: 540px;

  /* MAPTV: Header */
  --maptv-header-height: 64px; /* Set fixed height so we can calc step offsets */

  /* Logo */
  --logo-height: 36px;
  
  /* Election Center Navigation Subheader */
  --ec-navigation-height: 2rem;
  --ec-tile-height: 20rem;
  --ec-tile-width: 20rem;
  --color-election-center-bg: #302f2f;
  
  /* Share Scene */
  --share-image-background: #003b49;
  --share-image-text: #ffc845;
  --share-image-label-text: #fff;
}

@media (min-width: 576px) {
  :root {
    --maptv-header-height: 112px;
  }
}

div[class*='OfficeHoldersApp_termDates__'] {
    margin-top: -2rem;
}

div[class*='OfficeHoldersApp_officeHolderInfo__'] div[data-testid='spinner'] {
    animation: none;
    display: none !important;
}

/* "info box" styling for voting uncommitted instructions */
section[class*="PositionDescriptionBox"] > blockquote {
  outline-color: #bee5eb !important;  
  outline-style: 1px dashed !important;
  box-shadow: 0 2px 8px 0 rgba(223, 223, 223, 0.5) !important;
  font-style: italic !important;
  background-color: #d1ecf1;
  color: #0c5460;
  padding: 0.1rem .5rem .2rem .5rem !important;
}
@import 'theme.scss';

.section {
  text-align: left;
  h2 {
    letter-spacing: 2px;
    margin: 0 0 $space-sm;
    text-transform: uppercase;
  }
}

.header {
  text-align: center;
}

.blob {
  margin-bottom: $space-sm;
  // these styles are applied to markdown, and we do not have control over the semantic html until we have implemented markdown guidelines

  h1 {
    font-size: $text-md;
    letter-spacing: 1px;
    text-align: center;
  }
}

@import 'theme.scss';

$primary-color: getColor(var(--color-primary), $color-primary);

.candidateHeader {
  margin-bottom: $space-sm;
  display: flex;

  @media (min-width: $breakpoint-lg) {
    display: grid;
    grid-template-columns: 14rem auto;
  }

  h1 {
    letter-spacing: 1px;
    color: $primary-color;
    font-size: $text-lg;
    @media (min-width: $breakpoint-md) {
      font-size: $text-xxl;
    }
  }

  h2 {
    color: $primary-color;
    font-size: $text-sm;
    font-weight: $font-weight-regular;
    letter-spacing: 1px;
    margin: 0;
    margin-bottom: $space-xs;
  }

  div {
    justify-content: start;
    width: auto;
  }
}

.urls {
  margin-top: 0;
  margin-bottom: 0;
  flex-flow: row;
}

.runningMate {
  color: $color-gray-dark;
  margin-bottom: $space-xs;
  font-weight: $font-weight-regular;
}

.headshot {
  margin-bottom: $space-xs;
  padding-right: $padding-cozy;
  position: relative;
  @media screen and (min-width: $breakpoint-md) {
    padding-right: $padding-comfortable;
  }
}

.featured {
  background: var(--brand-success);
  border-radius: 0.15rem;
  bottom: 0;
  color: $color-white;
  font-size: 0.675rem;
  left: 0;
  padding: 0.5rem;
  right: 0;
  text-align: center;
  text-transform: uppercase;

  svg {
    margin-right: $space-xxs;
  }
}

.notification {
  margin-bottom: $space-sm;
}

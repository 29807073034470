@import 'theme.scss';

.button {
  background: transparent;
  border: none;
  color: getColor(var(--color-primary), $color-primary);
  cursor: pointer;
  font-size: $text-xl;
  width: 29px;

  &:hover {
    color: var(--link-color);
  }

  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}

.nav {
  background-color: #fff;
  display: none;

  &.open {
    display: block;
  }

  @media (min-width: $breakpoint-lg) {
    display: block;
    position: static;
  }

  @media (max-width: $breakpoint-lg) {
    height: calc(100vh - $nav-height);
    left: 0;
    padding: 1rem;
    position: absolute;
    top: calc($nav-height + 1rem);
    width: 100vw;
    z-index: 1234;
  }
}

a.navItem {
  color: getColor(var(--color-primary), $color-primary);
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: var(--link-color);
  }

  &.active {
    color: var(--brand-danger);

    &:hover {
      color: var(--brand-danger);
    }
  }

  @media (min-width: $breakpoint-lg) {
    margin-right: $space-sm;
  }

  @media (max-width: $breakpoint-lg) {
    display: block;
    margin-bottom: $space-xxs;
    padding: $space-xxs 0;
  }
}

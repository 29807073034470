@import 'theme.scss';

.scene {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - $nav-height);
  justify-content: center;

  @media (min-width: $breakpoint-sm) {
    height: calc(100vh - $nav-height-lg);
  }
}

.message {
  color: $color-gray-dark;
  font-size: 1.25rem;
  margin-top: 1rem;
}

@import 'theme.scss';

.container {
  margin-bottom: $space-sm;
}

/* Hidden on small screens, will show dropdown instead */
.root {
  display: none;
  list-style: none;
  margin-bottom: $space-sm;
  max-width: 45rem;
  padding: 0;
}

.select {
  margin-bottom: $space-xs;
}

.IssueList__Issue {
  align-items: center;
  background: $color-white;
  border: 1px solid $color-gray;
  cursor: pointer;
  display: flex;
  font-size: $text-sm;
  height: 100%;
  justify-content: center;
  line-height: $line-height-xs;
  min-height: $space-sm;
  width: 100%;
}

.IssueList__Issue:hover {
  background: $color-gray;
}

.IssueList__Issue__active {
  background: $color-black;
  color: $color-white;
}

.IssueList__Issue__long {
  padding: $padding-compact;
}

.IssueList__Stances a.SourcedSnippet {
  margin-bottom: $space-xs;
}

.IssueList__Stances h3 {
  margin-bottom: $space-xs;
}

@media (min-width: $breakpoint-lg) {
  .root {
    display: flex; /* IE Fix */
    display: grid;
    grid-auto-rows: minmax($space-sm, max-content);
    grid-gap: $space-xs;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: -0.1rem; // helps keep top inline with title on left side
  }

  .IssueList__Issue {
    border: none;
    box-shadow: 0 0 12px 0 rgba(180, 180, 180, 0.5);
  }

  .select {
    display: none;
  }

  .IssueList__Stances {
    box-shadow: 0 0 12px 0 rgba(180, 180, 180, 0.5);
    padding: $padding-cozy;
    position: relative;
  }

  .IssueList__Stances::before {
    background-color: $color-white;
    box-shadow: -4px 4px 4px 0 rgba(180, 180, 180, 0.3);
    content: '\00a0';
    display: block;
    height: 20px;
    left: $space-sm;
    position: absolute;
    top: -10px;
    transform: rotate(135deg);
    width: 20px;
  }

  .IssueList__Stances h3 {
    color: $color-gray-dark;
    font-weight: $font-weight-regular;
    letter-spacing: 1.75px;
    text-align: left;
    text-transform: uppercase;
  }
}

.SROnly {
  display: none;
}

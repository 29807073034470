@import 'theme.scss';

.container_outer {
  position: relative;
  height: $space-lg;

  @media (min-width: $breakpoint-md) {
    margin: $margin-comfortable 0;
  }
}

.candidateListContainer {
  box-shadow: 0 4px 4px 0 rgba(180, 180, 180, 0.2);
  min-height: $space-lg;
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $breakpoint-md) {
    box-shadow: none;
    min-height: $space-lg;
  }
}

.bottom_border {
  border-bottom: 1px solid $color-gray;
}

.hasBadge {
  min-height: 12rem;
}

.candidateList {
  align-items: flex-end;
  display: flex;
  list-style: none;
  margin: 0;
  min-height: inherit;
  min-width: 100%;
  overflow: scroll;
  padding: 0;
  position: absolute;
}

.nav {
  align-items: center;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  width: 100%;
}

.navBtn {
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid $color-gray;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 4px 0 rgba(128, 128, 128, 0.5);
  cursor: pointer;
  font-size: $text-xl;
  margin: 0;
  padding: $space-xxxs $space-xxs;
  position: absolute;
  top: 25%;
  width: 32px;
  z-index: 100;

  &:hover {
    background: rgba(255, 255, 255, 1);
    color: var(--link-color--hover);
  }
}

.hidden {
  display: none;
}

.leftNav {
  left: 0;
}

.rightNav {
  right: 0;
}

.item {
  list-style: none;
  &:last-child {
    margin-right: $space-xxs;
  }
}

// CREATES THE HEADSHOT ONLY CANDIDATELIST
.small {
  .candidateListContainer {
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: $space-xs;
    min-height: $space-md;

    @media (min-width: $breakpoint-lg) {
      margin-left: -$space-sm;
      margin-right: -$space-sm;
    }
  }
}
